import React from "react";

const FunFactWaves = () => {
  return (
    <svg
      viewBox="0 0 551 656"
      width="200"
      height="200"
      fill="none"
      fillOpacity="0.5"
      strokeOpacity="0.5"
      className="background-waves"
    >
      <path
        d="M14.8985 161.159C33.1849 158.262 33.0078 137.818 49.832 135.154C66.6562 132.489 74.2676 151.755 91.0918 149.091C107.916 146.426 109.201 125.75 126.025 123.086C142.85 120.421 150.461 139.688 167.285 137.023C184.109 134.358 185.395 113.683 202.219 111.018C219.043 108.353 226.654 127.62 243.478 124.955C260.303 122.29 261.588 101.615 278.412 98.9501C295.236 96.2854 302.848 115.552 319.672 112.887C336.496 110.222 337.781 89.5469 354.605 86.8822C371.43 84.2175 379.041 103.484 395.865 100.819C412.689 98.1546 413.975 77.4791 430.799 74.8144C447.623 72.1497 455.601 91.3581 472.058 88.7515"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M13.3652 151.476C31.6516 148.579 31.4746 128.135 48.2988 125.471C65.123 122.806 72.7344 142.073 89.5586 139.408C106.383 136.743 107.668 116.068 124.492 113.403C141.316 110.738 148.928 130.005 165.752 127.34C182.576 124.675 183.861 104 200.685 101.335C217.51 98.6704 225.121 117.937 241.945 115.272C258.769 112.607 260.055 91.9319 276.879 89.2672C293.703 86.6025 301.314 105.869 318.139 103.204C334.963 100.54 336.248 79.8641 353.072 77.1994C369.896 74.5347 377.508 93.8012 394.332 91.1365C411.156 88.4718 412.441 67.7962 429.266 65.1315C446.09 62.4668 454.068 81.6753 470.525 79.0686"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M11.8311 141.793C30.1175 138.896 29.9405 118.452 46.7646 115.788C63.5888 113.123 71.2002 132.39 88.0244 129.725C104.849 127.06 106.134 106.385 122.958 103.72C139.782 101.055 147.394 120.322 164.218 117.657C181.042 114.992 182.327 94.3167 199.151 91.6521C215.975 88.9874 223.587 108.254 240.411 105.589C257.235 102.924 258.52 82.2489 275.345 79.5842C292.169 76.9195 299.78 96.186 316.604 93.5213C333.429 90.8566 334.714 70.1811 351.538 67.5164C368.362 64.8517 375.974 84.1182 392.798 81.4535C409.622 78.7888 410.907 58.1132 427.731 55.4485C444.555 52.7839 452.533 71.9923 468.991 69.3856"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M10.2979 132.11C28.5843 129.214 28.4073 108.77 45.2314 106.105C62.0556 103.44 69.667 122.707 86.4912 120.042C103.315 117.377 104.601 96.7017 121.425 94.037C138.249 91.3723 145.86 110.639 162.685 107.974C179.509 105.309 180.794 84.6339 197.618 81.9692C214.442 79.3045 222.054 98.571 238.878 95.9063C255.702 93.2416 256.987 72.566 273.811 69.9014C290.636 67.2367 298.247 86.5031 315.071 83.8385C331.895 81.1738 333.181 60.4982 350.005 57.8335C366.829 55.1688 374.44 74.4353 391.265 71.7706C408.089 69.1059 409.374 48.4304 426.198 45.7657C443.022 43.101 451 62.3094 467.458 59.7028"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M8.76368 122.427C27.0501 119.531 26.8731 99.0866 43.6973 96.4219C60.5214 93.7572 68.1329 113.024 84.957 110.359C101.781 107.694 103.066 87.0187 119.891 84.3541C136.715 81.6894 144.326 100.956 161.15 98.2912C177.975 95.6265 179.26 74.9509 196.084 72.2862C212.908 69.6215 220.52 88.888 237.344 86.2233C254.168 83.5586 255.453 62.8831 272.277 60.2184C289.101 57.5537 296.713 76.8202 313.537 74.1555C330.361 71.4908 331.646 50.8152 348.471 48.1505C365.295 45.4859 372.906 64.7523 389.73 62.0876C406.555 59.423 407.84 38.7474 424.664 36.0827C441.488 33.418 449.466 52.6265 465.924 50.0198"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M7.23048 112.744C25.5169 109.848 25.3399 89.4036 42.1641 86.7389C58.9882 84.0742 66.5996 103.341 83.4238 100.676C100.248 98.0113 101.533 77.3358 118.357 74.6711C135.182 72.0064 142.793 91.2729 159.617 88.6082C176.441 85.9435 177.727 65.2679 194.551 62.6032C211.375 59.9385 218.986 79.205 235.81 76.5403C252.635 73.8756 253.92 53.2001 270.744 50.5354C287.568 47.8707 295.18 67.1372 312.004 64.4725C328.828 61.8078 330.113 41.1322 346.937 38.4676C363.762 35.8029 371.373 55.0693 388.197 52.4047C405.021 49.74 406.307 29.0644 423.131 26.3997C439.955 23.735 447.933 42.9435 464.391 40.3368"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M5.69728 103.061C23.9837 100.165 23.8067 79.7207 40.6308 77.056C57.455 74.3914 65.0664 93.6578 81.8906 90.9931C98.7148 88.3285 100 67.6529 116.824 64.9882C133.648 62.3235 141.26 81.59 158.084 78.9253C174.908 76.2606 176.193 55.5851 193.018 52.9204C209.842 50.2557 217.453 69.5222 234.277 66.8575C251.101 64.1928 252.387 43.5172 269.211 40.8525C286.035 38.1878 293.646 57.4543 310.471 54.7896C327.295 52.1249 328.58 31.4494 345.404 28.7847C362.228 26.12 369.84 45.3865 386.664 42.7218C403.488 40.0571 404.773 19.3815 421.598 16.7169C438.422 14.0522 446.4 33.2606 462.857 30.654"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M4.1631 93.3781C22.4495 90.4818 22.2725 70.0379 39.0967 67.3732C55.9208 64.7085 63.5323 83.975 80.3564 81.3103C97.1806 78.6456 98.4658 57.97 115.29 55.3053C132.114 52.6407 139.726 71.9071 156.55 69.2424C173.374 66.5778 174.659 45.9022 191.483 43.2375C208.308 40.5728 215.919 59.8393 232.743 57.1746C249.567 54.5099 250.853 33.8344 267.677 31.1697C284.501 28.505 292.112 47.7715 308.936 45.1068C325.761 42.4421 327.046 21.7665 343.87 19.1018C360.694 16.4371 368.306 35.7036 385.13 33.0389C401.954 30.3742 403.239 9.69868 420.063 7.03399C436.888 4.36931 444.865 23.5777 461.323 20.9711"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M16.4326 170.841C34.719 167.945 34.542 147.501 51.3662 144.836C68.1904 142.172 75.8018 161.438 92.626 158.774C109.45 156.109 110.735 135.433 127.56 132.769C144.384 130.104 151.995 149.37 168.819 146.706C185.643 144.041 186.929 123.365 203.753 120.701C220.577 118.036 228.188 137.303 245.013 134.638C261.837 131.973 263.122 111.298 279.946 108.633C296.77 105.968 304.382 125.235 321.206 122.57C338.03 119.905 339.315 99.2298 356.14 96.5651C372.964 93.9004 380.575 113.167 397.399 110.502C414.223 107.838 415.509 87.1619 432.333 84.4972C449.157 81.8326 457.135 101.041 473.593 98.4344"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M17.9658 180.524C36.2522 177.628 36.0752 157.184 52.8994 154.519C69.7236 151.855 77.335 171.121 94.1592 168.457C110.983 165.792 112.269 145.116 129.093 142.452C145.917 139.787 153.528 159.053 170.353 156.389C187.177 153.724 188.462 133.048 205.286 130.384C222.11 127.719 229.722 146.986 246.546 144.321C263.37 141.656 264.655 120.981 281.479 118.316C298.304 115.651 305.915 134.918 322.739 132.253C339.563 129.588 340.849 108.913 357.673 106.248C374.497 103.583 382.108 122.85 398.933 120.185C415.757 117.52 417.042 96.8449 433.866 94.1802C450.69 91.5155 458.668 110.724 475.126 108.117"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M19.499 190.207C37.7854 187.311 37.6084 166.867 54.4326 164.202C71.2568 161.538 78.8682 180.804 95.6924 178.139C112.517 175.475 113.802 154.799 130.626 152.134C147.45 149.47 155.062 168.736 171.886 166.072C188.71 163.407 189.995 142.731 206.819 140.067C223.643 137.402 231.255 156.668 248.079 154.004C264.903 151.339 266.188 130.663 283.013 127.999C299.837 125.334 307.448 144.601 324.272 141.936C341.097 139.271 342.382 118.596 359.206 115.931C376.03 113.266 383.642 132.533 400.466 129.868C417.29 127.203 418.575 106.528 435.399 103.863C452.223 101.198 460.201 120.407 476.659 117.8"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M21.0332 199.89C39.3196 196.994 39.1426 176.55 55.9668 173.885C72.791 171.221 80.4024 190.487 97.2266 187.822C114.051 185.158 115.336 164.482 132.16 161.817C148.984 159.153 156.596 178.419 173.42 175.755C190.244 173.09 191.529 152.414 208.353 149.75C225.178 147.085 232.789 166.351 249.613 163.687C266.437 161.022 267.723 140.346 284.547 137.682C301.371 135.017 308.982 154.284 325.807 151.619C342.631 148.954 343.916 128.279 360.74 125.614C377.564 122.949 385.176 142.216 402 139.551C418.824 136.886 420.109 116.211 436.933 113.546C453.758 110.881 461.735 130.09 478.193 127.483"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M22.5664 209.573C40.8528 206.677 40.6758 186.233 57.5 183.568C74.3242 180.904 81.9356 200.17 98.7598 197.505C115.584 194.841 116.869 174.165 133.693 171.5C150.517 168.836 158.129 188.102 174.953 185.438C191.777 182.773 193.062 162.097 209.887 159.433C226.711 156.768 234.322 176.034 251.146 173.37C267.971 170.705 269.256 150.029 286.08 147.365C302.904 144.7 310.516 163.967 327.34 161.302C344.164 158.637 345.449 137.962 362.273 135.297C379.098 132.632 386.709 151.899 403.533 149.234C420.357 146.569 421.643 125.894 438.467 123.229C455.291 120.564 463.269 139.773 479.726 137.166"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M24.1006 219.256C42.387 216.36 42.21 195.916 59.0342 193.251C75.8583 190.586 83.4698 209.853 100.294 207.188C117.118 204.524 118.403 183.848 135.228 181.183C152.052 178.519 159.663 197.785 176.487 195.12C193.311 192.456 194.597 171.78 211.421 169.115C228.245 166.451 235.856 185.717 252.681 183.053C269.505 180.388 270.79 159.712 287.614 157.048C304.438 154.383 312.05 173.649 328.874 170.985C345.698 168.32 346.983 147.644 363.808 144.98C380.632 142.315 388.243 161.582 405.067 158.917C421.891 156.252 423.177 135.577 440.001 132.912C456.825 130.247 464.803 149.456 481.261 146.849"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M25.6338 228.939C43.9202 226.043 43.7432 205.599 60.5674 202.934C77.3915 200.269 85.003 219.536 101.827 216.871C118.651 214.206 119.937 193.531 136.761 190.866C153.585 188.201 161.196 207.468 178.02 204.803C194.845 202.139 196.13 181.463 212.954 178.798C229.778 176.134 237.39 195.4 254.214 192.735C271.038 190.071 272.323 169.395 289.147 166.73C305.972 164.066 313.583 183.332 330.407 180.668C347.231 178.003 348.517 157.327 365.341 154.663C382.165 151.998 389.776 171.264 406.6 168.6C423.425 165.935 424.71 145.259 441.534 142.595C458.358 139.93 466.336 159.139 482.794 156.532"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M27.168 238.622C45.4544 235.726 45.2774 215.282 62.1016 212.617C78.9257 209.952 86.5371 229.219 103.361 226.554C120.185 223.889 121.471 203.214 138.295 200.549C155.119 197.884 162.73 217.151 179.555 214.486C196.379 211.822 197.664 191.146 214.488 188.481C231.312 185.817 238.924 205.083 255.748 202.418C272.572 199.754 273.857 179.078 290.682 176.413C307.506 173.749 315.117 193.015 331.941 190.351C348.766 187.686 350.051 167.01 366.875 164.346C383.699 161.681 391.31 180.947 408.135 178.283C424.959 175.618 426.244 154.942 443.068 152.278C459.892 149.613 467.87 168.822 484.328 166.215"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M28.7012 248.305C46.9876 245.408 46.8106 224.965 63.6348 222.3C80.4589 219.635 88.0704 238.902 104.895 236.237C121.719 233.572 123.004 212.897 139.828 210.232C156.652 207.567 164.264 226.834 181.088 224.169C197.912 221.504 199.197 200.829 216.021 198.164C232.846 195.499 240.457 214.766 257.281 212.101C274.105 209.437 275.391 188.761 292.215 186.096C309.039 183.432 316.65 202.698 333.475 200.033C350.299 197.369 351.584 176.693 368.408 174.028C385.232 171.364 392.844 190.63 409.668 187.966C426.492 185.301 427.777 164.625 444.601 161.961C461.426 159.296 469.403 178.504 485.861 175.898"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M30.2344 257.988C48.5208 255.091 48.3438 234.647 65.168 231.983C81.9921 229.318 89.6036 248.584 106.428 245.92C123.252 243.255 124.537 222.58 141.361 219.915C158.185 217.25 165.797 236.517 182.621 233.852C199.445 231.187 200.73 210.512 217.555 207.847C234.379 205.182 241.99 224.449 258.814 221.784C275.639 219.119 276.924 198.444 293.748 195.779C310.572 193.114 318.184 212.381 335.008 209.716C351.832 207.052 353.117 186.376 369.941 183.711C386.765 181.047 394.377 200.313 411.201 197.648C428.025 194.984 429.31 174.308 446.135 171.643C462.959 168.979 470.937 188.187 487.394 185.581"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M31.7686 267.671C50.055 264.774 49.878 244.33 66.7021 241.666C83.5263 239.001 91.1377 258.268 107.962 255.603C124.786 252.938 126.071 232.263 142.895 229.598C159.72 226.933 167.331 246.2 184.155 243.535C200.979 240.87 202.265 220.195 219.089 217.53C235.913 214.865 243.524 234.132 260.349 231.467C277.173 228.803 278.458 208.127 295.282 205.462C312.106 202.798 319.718 222.064 336.542 219.399C353.366 216.735 354.651 196.059 371.475 193.394C388.3 190.73 395.911 209.996 412.735 207.332C429.559 204.667 430.845 183.991 447.669 181.327C464.493 178.662 472.471 197.87 488.929 195.264"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M33.3018 277.354C51.5882 274.457 51.4112 254.013 68.2353 251.349C85.0595 248.684 92.6709 267.95 109.495 265.286C126.319 262.621 127.605 241.945 144.429 239.281C161.253 236.616 168.864 255.883 185.688 253.218C202.513 250.553 203.798 229.878 220.622 227.213C237.446 224.548 245.058 243.815 261.882 241.15C278.706 238.485 279.991 217.81 296.815 215.145C313.64 212.48 321.251 231.747 338.075 229.082C354.899 226.418 356.185 205.742 373.009 203.077C389.833 200.413 397.444 219.679 414.268 217.014C431.093 214.35 432.378 193.674 449.202 191.009C466.026 188.345 474.004 207.553 490.462 204.947"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M34.836 287.036C53.1224 284.14 52.9453 263.696 69.7695 261.032C86.5937 258.367 94.2051 277.633 111.029 274.969C127.853 272.304 129.139 251.628 145.963 248.964C162.787 246.299 170.398 265.565 187.223 262.901C204.047 260.236 205.332 239.561 222.156 236.896C238.98 234.231 246.592 253.498 263.416 250.833C280.24 248.168 281.525 227.493 298.35 224.828C315.174 222.163 322.785 241.43 339.609 238.765C356.433 236.1 357.719 215.425 374.543 212.76C391.367 210.095 398.978 229.362 415.803 226.697C432.627 224.033 433.912 203.357 450.736 200.692C467.56 198.028 475.538 217.236 491.996 214.629"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M36.3692 296.719C54.6556 293.823 54.4786 273.379 71.3027 270.714C88.1269 268.05 95.7383 287.316 112.562 284.652C129.387 281.987 130.672 261.311 147.496 258.647C164.32 255.982 171.932 275.248 188.756 272.584C205.58 269.919 206.865 249.244 223.689 246.579C240.514 243.914 248.125 263.181 264.949 260.516C281.773 257.851 283.059 237.176 299.883 234.511C316.707 231.846 324.318 251.113 341.143 248.448C357.967 245.783 359.252 225.108 376.076 222.443C392.9 219.778 400.512 239.045 417.336 236.38C434.16 233.716 435.445 213.04 452.269 210.375C469.094 207.711 477.071 226.919 493.529 224.312"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M37.9024 306.402C56.1888 303.506 56.0118 283.062 72.8359 280.397C89.6601 277.733 97.2715 296.999 114.096 294.334C130.92 291.67 132.205 270.994 149.029 268.33C165.853 265.665 173.465 284.931 190.289 282.267C207.113 279.602 208.398 258.926 225.223 256.262C242.047 253.597 249.658 272.863 266.482 270.199C283.307 267.534 284.592 246.859 301.416 244.194C318.24 241.529 325.852 260.796 342.676 258.131C359.5 255.466 360.785 234.791 377.609 232.126C394.433 229.461 402.045 248.728 418.869 246.063C435.693 243.398 436.978 222.723 453.803 220.058C470.627 217.393 478.605 236.602 495.062 233.995"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M39.4365 316.085C57.7229 313.189 57.5459 292.745 74.3701 290.08C91.1943 287.416 98.8057 306.682 115.63 304.017C132.454 301.353 133.739 280.677 150.563 278.012C167.388 275.348 174.999 294.614 191.823 291.949C208.647 289.285 209.933 268.609 226.757 265.945C243.581 263.28 251.192 282.546 268.017 279.882C284.841 277.217 286.126 256.541 302.95 253.877C319.774 251.212 327.386 270.478 344.21 267.814C361.034 265.149 362.319 244.474 379.143 241.809C395.968 239.144 403.579 258.411 420.403 255.746C437.227 253.081 438.513 232.406 455.337 229.741C472.161 227.076 480.139 246.285 496.597 243.678"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M40.9707 325.768C59.2571 322.872 59.0801 302.428 75.9043 299.763C92.7285 297.099 100.34 316.365 117.164 313.7C133.988 311.036 135.273 290.36 152.098 287.695C168.922 285.031 176.533 304.297 193.357 301.632C210.182 298.968 211.467 278.292 228.291 275.628C245.115 272.963 252.727 292.229 269.551 289.565C286.375 286.9 287.66 266.224 304.484 263.56C321.308 260.895 328.92 280.161 345.744 277.497C362.568 274.832 363.853 254.157 380.678 251.492C397.502 248.827 405.113 268.094 421.937 265.429C438.762 262.764 440.047 242.089 456.871 239.424C473.695 236.759 481.673 255.968 498.131 253.361"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M42.5039 335.451C60.7903 332.555 60.6133 312.111 77.4375 309.446C94.2617 306.781 101.873 326.048 118.697 323.383C135.521 320.719 136.807 300.043 153.631 297.378C170.455 294.714 178.066 313.98 194.891 311.315C211.715 308.651 213 287.975 229.824 285.311C246.648 282.646 254.26 301.912 271.084 299.248C287.908 296.583 289.193 275.907 306.018 273.243C322.842 270.578 330.453 289.844 347.277 287.18C364.101 284.515 365.387 263.84 382.211 261.175C399.035 258.51 406.646 277.777 423.471 275.112C440.295 272.447 441.58 251.772 458.404 249.107C475.228 246.442 483.206 265.651 499.664 263.044"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M44.0381 345.134C62.3245 342.238 62.1475 321.794 78.9717 319.129C95.7958 316.464 103.407 335.731 120.231 333.066C137.056 330.402 138.341 309.726 155.165 307.061C171.989 304.397 179.601 323.663 196.425 320.998C213.249 318.334 214.534 297.658 231.358 294.993C248.183 292.329 255.794 311.595 272.618 308.931C289.442 306.266 290.728 285.59 307.552 282.926C324.376 280.261 331.987 299.527 348.811 296.863C365.636 294.198 366.921 273.522 383.745 270.858C400.569 268.193 408.181 287.46 425.005 284.795C441.829 282.13 443.114 261.455 459.938 258.79C476.763 256.125 484.74 275.334 501.198 272.727"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M45.5713 354.817C63.8577 351.921 63.6807 331.477 80.5049 328.812C97.329 326.147 104.94 345.414 121.765 342.749C138.589 340.084 139.874 319.409 156.698 316.744C173.522 314.08 181.134 333.346 197.958 330.681C214.782 328.017 216.067 307.341 232.892 304.676C249.716 302.012 257.327 321.278 274.151 318.613C290.975 315.949 292.261 295.273 309.085 292.609C325.909 289.944 333.52 309.21 350.345 306.546C367.169 303.881 368.454 283.205 385.278 280.541C402.102 277.876 409.714 297.142 426.538 294.478C443.362 291.813 444.647 271.138 461.472 268.473C478.296 265.808 486.274 285.017 502.731 282.41"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M47.1045 364.5C65.3909 361.604 65.2139 341.16 82.0381 338.495C98.8622 335.83 106.474 355.097 123.298 352.432C140.122 349.767 141.407 329.092 158.231 326.427C175.056 323.762 182.667 343.029 199.491 340.364C216.315 337.699 217.601 317.024 234.425 314.359C251.249 311.695 258.86 330.961 275.685 328.296C292.509 325.632 293.794 304.956 310.618 302.291C327.442 299.627 335.054 318.893 351.878 316.228C368.702 313.564 369.987 292.888 386.811 290.224C403.636 287.559 411.247 306.825 428.071 304.161C444.895 301.496 446.181 280.82 463.005 278.156C479.829 275.491 487.807 294.699 504.265 292.093"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M48.6387 374.183C66.9251 371.286 66.7481 350.842 83.5723 348.178C100.396 345.513 108.008 364.78 124.832 362.115C141.656 359.45 142.941 338.775 159.766 336.11C176.59 333.445 184.201 352.712 201.025 350.047C217.85 347.382 219.135 326.707 235.959 324.042C252.783 321.377 260.395 340.644 277.219 337.979C294.043 335.314 295.328 314.639 312.152 311.974C328.976 309.31 336.588 328.576 353.412 325.911C370.236 323.247 371.521 302.571 388.346 299.906C405.17 297.242 412.781 316.508 429.605 313.843C446.43 311.179 447.715 290.503 464.539 287.839C481.363 285.174 489.341 304.382 505.799 301.776"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M50.1719 383.866C68.4583 380.969 68.2813 360.525 85.1055 357.861C101.93 355.196 109.541 374.463 126.365 371.798C143.189 369.133 144.475 348.458 161.299 345.793C178.123 343.128 185.734 362.395 202.559 359.73C219.383 357.065 220.668 336.39 237.492 333.725C254.316 331.06 261.928 350.327 278.752 347.662C295.576 344.997 296.861 324.322 313.685 321.657C330.51 318.993 338.121 338.259 354.945 335.594C371.769 332.93 373.055 312.254 389.879 309.589C406.703 306.925 414.314 326.191 431.139 323.526C447.963 320.862 449.248 300.186 466.072 297.522C482.896 294.857 490.874 314.065 507.332 311.459"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M51.7061 393.549C69.9925 390.652 69.8155 370.208 86.6396 367.544C103.464 364.879 111.075 384.146 127.899 381.481C144.724 378.816 146.009 358.141 162.833 355.476C179.657 352.811 187.269 372.078 204.093 369.413C220.917 366.748 222.202 346.073 239.026 343.408C255.85 340.743 263.462 360.01 280.286 357.345C297.11 354.68 298.395 334.005 315.22 331.34C332.044 328.676 339.655 347.942 356.479 345.277C373.304 342.613 374.589 321.937 391.413 319.272C408.237 316.608 415.849 335.874 432.673 333.209C449.497 330.545 450.782 309.869 467.606 307.205C484.43 304.54 492.408 323.748 508.866 321.142"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M53.2393 403.232C71.5257 400.335 71.3487 379.891 88.1728 377.227C104.997 374.562 112.608 393.828 129.433 391.164C146.257 388.499 147.542 367.823 164.366 365.159C181.19 362.494 188.802 381.761 205.626 379.096C222.45 376.431 223.735 355.756 240.56 353.091C257.384 350.426 264.995 369.693 281.819 367.028C298.643 364.363 299.929 343.688 316.753 341.023C333.577 338.358 341.188 357.625 358.013 354.96C374.837 352.295 376.122 331.62 392.946 328.955C409.77 326.291 417.382 345.557 434.206 342.892C451.03 340.228 452.315 319.552 469.14 316.887C485.964 314.223 493.942 333.431 510.399 330.824"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M54.7735 412.914C73.0599 410.018 72.8828 389.574 89.707 386.909C106.531 384.245 114.143 403.511 130.967 400.847C147.791 398.182 149.076 377.506 165.9 374.842C182.725 372.177 190.336 391.443 207.16 388.779C223.984 386.114 225.27 365.438 242.094 362.774C258.918 360.109 266.529 379.376 283.353 376.711C300.178 374.046 301.463 353.371 318.287 350.706C335.111 348.041 342.723 367.308 359.547 364.643C376.371 361.978 377.656 341.303 394.48 338.638C411.305 335.973 418.916 355.24 435.74 352.575C452.564 349.911 453.85 329.235 470.674 326.57C487.498 323.906 495.476 343.114 511.933 340.507"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M56.3067 422.597C74.5931 419.701 74.4161 399.257 91.2402 396.593C108.064 393.928 115.676 413.194 132.5 410.53C149.324 407.865 150.609 387.189 167.434 384.525C184.258 381.86 191.869 401.126 208.693 398.462C225.517 395.797 226.803 375.122 243.627 372.457C260.451 369.792 268.062 389.059 284.887 386.394C301.711 383.729 302.996 363.054 319.82 360.389C336.644 357.724 344.256 376.991 361.08 374.326C377.904 371.661 379.189 350.986 396.014 348.321C412.838 345.657 420.449 364.923 437.273 362.258C454.098 359.594 455.383 338.918 472.207 336.253C489.031 333.589 497.009 352.797 513.467 350.19"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M57.8399 432.28C76.1263 429.384 75.9493 408.94 92.7734 406.275C109.598 403.611 117.209 422.877 134.033 420.213C150.857 417.548 152.143 396.872 168.967 394.208C185.791 391.543 193.402 410.809 210.227 408.145C227.051 405.48 228.336 384.804 245.16 382.14C261.984 379.475 269.596 398.742 286.42 396.077C303.244 393.412 304.529 372.737 321.353 370.072C338.178 367.407 345.789 386.674 362.613 384.009C379.437 381.344 380.723 360.669 397.547 358.004C414.371 355.339 421.982 374.606 438.807 371.941C455.631 369.276 456.916 348.601 473.74 345.936C490.564 343.272 498.542 362.48 515 359.873"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M59.374 441.963C77.6604 439.067 77.4834 418.623 94.3076 415.958C111.132 413.294 118.743 432.56 135.567 429.895C152.392 427.231 153.677 406.555 170.501 403.89C187.325 401.226 194.937 420.492 211.761 417.828C228.585 415.163 229.87 394.487 246.694 391.823C263.518 389.158 271.13 408.424 287.954 405.76C304.778 403.095 306.063 382.419 322.888 379.755C339.712 377.09 347.323 396.357 364.147 393.692C380.972 391.027 382.257 370.352 399.081 367.687C415.905 365.022 423.517 384.289 440.341 381.624C457.165 378.959 458.45 358.284 475.274 355.619C492.098 352.954 500.076 372.163 516.534 369.556"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M60.9072 451.646C79.1936 448.75 79.0166 428.306 95.8408 425.641C112.665 422.976 120.276 442.243 137.101 439.578C153.925 436.914 155.21 416.238 172.034 413.573C188.858 410.909 196.47 430.175 213.294 427.51C230.118 424.846 231.403 404.17 248.227 401.505C265.052 398.841 272.663 418.107 289.487 415.443C306.311 412.778 307.597 392.102 324.421 389.438C341.245 386.773 348.856 406.039 365.681 403.375C382.505 400.71 383.79 380.034 400.614 377.37C417.438 374.705 425.05 393.972 441.874 391.307C458.698 388.642 459.983 367.967 476.807 365.302C493.632 362.637 501.61 381.846 518.067 379.239"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M62.4414 461.329C80.7278 458.433 80.5508 437.989 97.375 435.324C114.199 432.659 121.811 451.926 138.635 449.261C155.459 446.596 156.744 425.921 173.568 423.256C190.392 420.591 198.004 439.858 214.828 437.193C231.652 434.529 232.937 413.853 249.762 411.188C266.586 408.524 274.197 427.79 291.021 425.125C307.846 422.461 309.131 401.785 325.955 399.12C342.779 396.456 350.391 415.722 367.215 413.058C384.039 410.393 385.324 389.717 402.148 387.053C418.973 384.388 426.584 403.654 443.408 400.99C460.232 398.325 461.518 377.649 478.342 374.985C495.166 372.32 503.144 391.529 519.601 388.922"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M63.9746 471.012C82.261 468.116 82.084 447.672 98.9082 445.007C115.732 442.342 123.344 461.609 140.168 458.944C156.992 456.28 158.277 435.604 175.102 432.939C191.926 430.275 199.537 449.541 216.361 446.876C233.185 444.212 234.471 423.536 251.295 420.871C268.119 418.207 275.73 437.473 292.555 434.809C309.379 432.144 310.664 411.468 327.488 408.804C344.312 406.139 351.924 425.405 368.748 422.741C385.572 420.076 386.857 399.4 403.682 396.736C420.506 394.071 428.117 413.338 444.941 410.673C461.765 408.008 463.051 387.333 479.875 384.668C496.699 382.003 504.677 401.212 521.135 398.605"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M65.5088 480.695C83.7952 477.799 83.6182 457.355 100.442 454.69C117.267 452.025 124.878 471.292 141.702 468.627C158.526 465.962 159.812 445.287 176.636 442.622C193.46 439.957 201.071 459.224 217.895 456.559C234.72 453.895 236.005 433.219 252.829 430.554C269.653 427.89 277.265 447.156 294.089 444.491C310.913 441.827 312.198 421.151 329.022 418.486C345.847 415.822 353.458 435.088 370.282 432.424C387.106 429.759 388.392 409.083 405.216 406.419C422.04 403.754 429.651 423.02 446.475 420.356C463.3 417.691 464.585 397.015 481.409 394.351C498.233 391.686 506.211 410.895 522.669 408.288"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M67.042 490.378C85.3284 487.481 85.1514 467.038 101.976 464.373C118.8 461.708 126.411 480.975 143.235 478.31C160.06 475.645 161.345 454.97 178.169 452.305C194.993 449.64 202.605 468.907 219.429 466.242C236.253 463.577 237.538 442.902 254.362 440.237C271.186 437.572 278.798 456.839 295.622 454.174C312.446 451.51 313.731 430.834 330.556 428.169C347.38 425.505 354.991 444.771 371.815 442.106C388.64 439.442 389.925 418.766 406.749 416.101C423.573 413.437 431.185 432.703 448.009 430.039C464.833 427.374 466.118 406.698 482.942 404.034C499.766 401.369 507.744 420.577 524.202 417.971"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M68.5752 500.061C86.8616 497.164 86.6846 476.72 103.509 474.056C120.333 471.391 127.944 490.657 144.769 487.993C161.593 485.328 162.878 464.653 179.702 461.988C196.526 459.323 204.138 478.59 220.962 475.925C237.786 473.26 239.071 452.585 255.895 449.92C272.72 447.255 280.331 466.522 297.155 463.857C313.979 461.192 315.265 440.517 332.089 437.852C348.913 435.187 356.524 454.454 373.349 451.789C390.173 449.125 391.458 428.449 408.282 425.784C425.106 423.12 432.718 442.386 449.542 439.721C466.366 437.057 467.651 416.381 484.475 413.716C501.3 411.052 509.277 430.26 525.735 427.654"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M70.1094 509.744C88.3958 506.847 88.2188 486.403 105.043 483.739C121.867 481.074 129.479 500.341 146.303 497.676C163.127 495.011 164.412 474.336 181.236 471.671C198.06 469.006 205.672 488.273 222.496 485.608C239.32 482.943 240.605 462.268 257.43 459.603C274.254 456.938 281.865 476.205 298.689 473.54C315.514 470.876 316.799 450.2 333.623 447.535C350.447 444.871 358.059 464.137 374.883 461.472C391.707 458.808 392.992 438.132 409.816 435.467C426.64 432.803 434.252 452.069 451.076 449.405C467.9 446.74 469.185 426.064 486.01 423.4C502.834 420.735 510.812 439.943 527.269 437.337"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M71.6426 519.427C89.929 516.53 89.752 496.086 106.576 493.422C123.4 490.757 131.012 510.023 147.836 507.359C164.66 504.694 165.945 484.018 182.769 481.354C199.594 478.689 207.205 497.956 224.029 495.291C240.853 492.626 242.139 471.951 258.963 469.286C275.787 466.621 283.398 485.888 300.223 483.223C317.047 480.558 318.332 459.883 335.156 457.218C351.98 454.553 359.592 473.82 376.416 471.155C393.24 468.491 394.525 447.815 411.35 445.15C428.174 442.486 435.785 461.752 452.609 459.087C469.433 456.423 470.719 435.747 487.543 433.082C504.367 430.418 512.345 449.626 528.803 447.02"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M73.1768 529.109C91.4632 526.213 91.2862 505.769 108.11 503.105C124.935 500.44 132.546 519.706 149.37 517.042C166.194 514.377 167.48 493.701 184.304 491.037C201.128 488.372 208.739 507.638 225.563 504.974C242.388 502.309 243.673 481.634 260.497 478.969C277.321 476.304 284.933 495.571 301.757 492.906C318.581 490.241 319.866 469.566 336.69 466.901C353.515 464.236 361.126 483.503 377.95 480.838C394.774 478.173 396.06 457.498 412.884 454.833C429.708 452.168 437.319 471.435 454.143 468.77C470.968 466.106 472.253 445.43 489.077 442.765C505.901 440.101 513.879 459.309 530.337 456.702"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M74.71 538.793C92.9964 535.896 92.8194 515.452 109.644 512.788C126.468 510.123 134.079 529.389 150.903 526.725C167.727 524.06 169.013 503.384 185.837 500.72C202.661 498.055 210.272 517.322 227.097 514.657C243.921 511.992 245.206 491.317 262.03 488.652C278.854 485.987 286.466 505.254 303.29 502.589C320.114 499.924 321.399 479.249 338.224 476.584C355.048 473.919 362.659 493.186 379.483 490.521C396.307 487.857 397.593 467.181 414.417 464.516C431.241 461.852 438.852 481.118 455.677 478.453C472.501 475.789 473.786 455.113 490.61 452.448C507.434 449.784 515.412 468.992 531.87 466.386"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M76.2432 548.475C94.5296 545.579 94.3526 525.135 111.177 522.47C128.001 519.806 135.612 539.072 152.437 536.408C169.261 533.743 170.546 513.067 187.37 510.403C204.194 507.738 211.806 527.004 228.63 524.34C245.454 521.675 246.739 500.999 263.563 498.335C280.388 495.67 287.999 514.937 304.823 512.272C321.647 509.607 322.933 488.932 339.757 486.267C356.581 483.602 364.192 502.869 381.017 500.204C397.841 497.539 399.126 476.864 415.95 474.199C432.774 471.534 440.386 490.801 457.21 488.136C474.034 485.472 475.319 464.796 492.143 462.131C508.968 459.467 516.945 478.675 533.403 476.068"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M77.7774 558.158C96.0638 555.262 95.8868 534.818 112.711 532.153C129.535 529.489 137.147 548.755 153.971 546.09C170.795 543.426 172.08 522.75 188.904 520.085C205.728 517.421 213.34 536.687 230.164 534.023C246.988 531.358 248.273 510.682 265.098 508.018C281.922 505.353 289.533 524.619 306.357 521.955C323.182 519.29 324.467 498.615 341.291 495.95C358.115 493.285 365.727 512.552 382.551 509.887C399.375 507.222 400.66 486.547 417.484 483.882C434.308 481.217 441.92 500.484 458.744 497.819C475.568 495.154 476.853 474.479 493.678 471.814C510.502 469.149 518.48 488.358 534.937 485.751"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M79.3106 567.841C97.597 564.945 97.42 544.501 114.244 541.836C131.068 539.172 138.68 558.438 155.504 555.773C172.328 553.109 173.613 532.433 190.437 529.768C207.262 527.104 214.873 546.37 231.697 543.705C248.521 541.041 249.807 520.365 266.631 517.701C283.455 515.036 291.066 534.302 307.891 531.638C324.715 528.973 326 508.297 342.824 505.633C359.648 502.968 367.26 522.234 384.084 519.57C400.908 516.905 402.193 496.23 419.017 493.565C435.842 490.9 443.453 510.167 460.277 507.502C477.101 504.837 478.387 484.162 495.211 481.497C512.035 478.832 520.013 498.041 536.471 495.434"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M80.8447 577.524C99.1311 574.628 98.9541 554.184 115.778 551.519C132.602 548.854 140.214 568.121 157.038 565.456C173.862 562.791 175.147 542.116 191.972 539.451C208.796 536.787 216.407 556.053 233.231 553.388C250.056 550.724 251.341 530.048 268.165 527.383C284.989 524.719 292.601 543.985 309.425 541.32C326.249 538.656 327.534 517.98 344.358 515.316C361.182 512.651 368.794 531.917 385.618 529.253C402.442 526.588 403.727 505.912 420.552 503.248C437.376 500.583 444.987 519.849 461.811 517.185C478.636 514.52 479.921 493.845 496.745 491.18C513.569 488.515 521.547 507.724 538.005 505.117"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M82.3779 587.207C100.664 584.311 100.487 563.867 117.312 561.202C134.136 558.537 141.747 577.804 158.571 575.139C175.395 572.474 176.681 551.799 193.505 549.134C210.329 546.469 217.94 565.736 234.765 563.071C251.589 560.406 252.874 539.731 269.698 537.066C286.522 534.402 294.134 553.668 310.958 551.003C327.782 548.339 329.067 527.663 345.892 524.998C362.716 522.334 370.327 541.6 387.151 538.936C403.975 536.271 405.261 515.595 422.085 512.931C438.909 510.266 446.52 529.532 463.345 526.868C480.169 524.203 481.454 503.527 498.278 500.863C515.102 498.198 523.08 517.406 539.538 514.8"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M83.9121 596.89C102.199 593.994 102.022 573.55 118.846 570.885C135.67 568.22 143.281 587.487 160.105 584.822C176.93 582.157 178.215 561.482 195.039 558.817C211.863 556.153 219.475 575.419 236.299 572.754C253.123 570.09 254.408 549.414 271.232 546.749C288.057 544.085 295.668 563.351 312.492 560.686C329.316 558.022 330.602 537.346 347.426 534.682C364.25 532.017 371.861 551.283 388.685 548.619C405.51 545.954 406.795 525.278 423.619 522.614C440.443 519.949 448.055 539.215 464.879 536.551C481.703 533.886 482.988 513.211 499.812 510.546C516.637 507.881 524.614 527.09 541.072 524.483"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M85.4453 606.573C103.732 603.677 103.555 583.233 120.379 580.568C137.203 577.903 144.814 597.17 161.639 594.505C178.463 591.84 179.748 571.165 196.572 568.5C213.396 565.835 221.008 585.102 237.832 582.437C254.656 579.772 255.941 559.097 272.766 556.432C289.59 553.768 297.201 573.034 314.025 570.369C330.85 567.705 332.135 547.029 348.959 544.364C365.783 541.7 373.395 560.966 390.219 558.301C407.043 555.637 408.328 534.961 425.152 532.297C441.976 529.632 449.588 548.898 466.412 546.234C483.236 543.569 484.521 522.893 501.346 520.229C518.17 517.564 526.148 536.772 542.605 534.166"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M86.9785 616.256C105.265 613.359 105.088 592.915 121.912 590.251C138.736 587.586 146.348 606.853 163.172 604.188C179.996 601.523 181.281 580.848 198.105 578.183C214.93 575.518 222.541 594.785 239.365 592.12C256.189 589.455 257.475 568.78 274.299 566.115C291.123 563.45 298.734 582.717 315.559 580.052C332.383 577.387 333.668 556.712 350.492 554.047C367.316 551.383 374.928 570.649 391.752 567.984C408.576 565.32 409.861 544.644 426.685 541.979C443.51 539.315 451.121 558.581 467.945 555.916C484.769 553.252 486.055 532.576 502.879 529.912C519.703 527.247 527.681 546.455 544.139 543.849"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M88.5127 625.939C106.799 623.043 106.622 602.599 123.446 599.934C140.27 597.269 147.882 616.536 164.706 613.871C181.53 611.206 182.815 590.531 199.64 587.866C216.464 585.201 224.075 604.468 240.899 601.803C257.724 599.138 259.009 578.463 275.833 575.798C292.657 573.133 300.269 592.4 317.093 589.735C333.917 587.071 335.202 566.395 352.026 563.73C368.85 561.066 376.462 580.332 393.286 577.667C410.11 575.003 411.395 554.327 428.22 551.662C445.044 548.998 452.655 568.264 469.479 565.6C486.304 562.935 487.589 542.259 504.413 539.595C521.237 536.93 529.215 556.138 545.673 553.532"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M90.0459 635.622C108.332 632.725 108.155 612.281 124.979 609.617C141.804 606.952 149.415 626.219 166.239 623.554C183.063 620.889 184.349 600.214 201.173 597.549C217.997 594.884 225.608 614.151 242.433 611.486C259.257 608.821 260.542 588.146 277.366 585.481C294.19 582.816 301.802 602.083 318.626 599.418C335.45 596.753 336.735 576.078 353.559 573.413C370.384 570.749 377.995 590.015 394.819 587.35C411.643 584.686 412.929 564.01 429.753 561.345C446.577 558.681 454.188 577.947 471.013 575.282C487.837 572.618 489.122 551.942 505.946 549.278C522.77 546.613 530.748 565.821 547.206 563.215"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M91.5801 645.305C109.866 642.408 109.689 621.964 126.514 619.3C143.338 616.635 150.949 635.901 167.773 633.237C184.598 630.572 185.883 609.896 202.707 607.232C219.531 604.567 227.143 623.834 243.967 621.169C260.791 618.504 262.076 597.829 278.9 595.164C295.725 592.499 303.336 611.766 320.16 609.101C336.984 606.436 338.27 585.761 355.094 583.096C371.918 580.431 379.529 599.698 396.353 597.033C413.178 594.368 414.463 573.693 431.287 571.028C448.111 568.364 455.723 587.63 472.547 584.965C489.371 582.301 490.656 561.625 507.48 558.96C524.305 556.296 532.282 575.504 548.74 572.897"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
      <path
        d="M93.1133 654.987C111.4 652.091 111.223 631.647 128.047 628.982C144.871 626.318 152.482 645.584 169.307 642.92C186.131 640.255 187.416 619.579 204.24 616.915C221.064 614.25 228.676 633.516 245.5 630.852C262.324 628.187 263.609 607.511 280.434 604.847C297.258 602.182 304.869 621.449 321.693 618.784C338.517 616.119 339.803 595.444 356.627 592.779C373.451 590.114 381.062 609.381 397.887 606.716C414.711 604.051 415.996 583.376 432.82 580.711C449.644 578.046 457.256 597.313 474.08 594.648C490.904 591.983 492.189 571.308 509.014 568.643C525.838 565.979 533.816 585.187 550.273 582.58"
        stroke="#d9ebfc"
        strokeWidth="1"
      />
    </svg>
  );
};
export default FunFactWaves;
