import "./App.css";
import React from "react";
import device from "current-device";

import FunFactLogo from "./components/FunFactLogo";
import FunFactWaves from "./components/FunFactWaves";

function App() {
  const redirectUrls = React.useCallback(() => {
    if (process.env.REACT_APP_IOS_STORE?.length > 0 && device.ios()) {
      window.location = process.env.REACT_APP_IOS_STORE;
    } else if (
      process.env.REACT_APP_GOOGLE_STORE?.length > 0 &&
      device.andriod()
    ) {
      window.location = process.env.REACT_APP_GOOGLE_STORE;
    } else if (process.env.REACT_APP_WEBSITE?.length > 0) {
      window.location = process.env.REACT_APP_WEBSITE;
    }
  }, []);

  React.useEffect(() => {
    redirectUrls();
  }, [redirectUrls]);

  return (
    <div className="App">
      <header
        className={`App-header ${
          process.env.REACT_APP_TITLE === "Fun Fact" && "funfact-green"
        }`}
      >
        <div className="content">
          {process.env.REACT_APP_TITLE === "Fun Fact" && <FunFactLogo />}
          <p>{`Welcome to ${process.env.REACT_APP_TITLE}! Please continue to one of these pages.`}</p>
          <div className="buttonWrapper">
            {process.env.REACT_APP_APP_LINK?.length > 0 && (
              <a
                className="App-link"
                href={`${process.env.REACT_APP_APP_LINK}${window.location.search}`}
                rel="noopener noreferrer"
              >
                {`${process.env.REACT_APP_TITLE} App`}
              </a>
            )}
            {process.env.REACT_APP_WEBSITE?.length > 0 && (
              <a
                className="App-link"
                href={`${process.env.REACT_APP_WEBSITE}${window.location.search}`}
                rel="noopener noreferrer"
              >
                Website
              </a>
            )}
            {process.env.REACT_APP_IOS_STORE?.length > 0 && (
              <a
                className="App-link"
                href={process.env.REACT_APP_IOS_STORE}
                rel="noreferrer"
              >
                Apple Store
              </a>
            )}
            {process.env.REACT_APP_GOOGLE_STORE?.length > 0 && (
              <a
                className="App-link"
                href={process.env.REACT_APP_GOOGLE_STORE}
                rel="noreferrer"
              >
                Google Store
              </a>
            )}
          </div>
        </div>
        {process.env.REACT_APP_TITLE === "Fun Fact" && <FunFactWaves />}
      </header>
    </div>
  );
}

export default App;
