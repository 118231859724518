import React from "react";

const FunFactLogo = () => {
  return (
    <svg
      width="304"
      height="304"
      viewBox="0 0 104 104"
      fill="none"
      className="App-logo"
    >
      <path
        d="M47.0384 48.0535L56.328 46.5822L56.3457 35.3322L47.0561 36.8035L47.0454 30.5817C47.1049 26.2234 48.2435 23.9436 51.6814 23.3991C53.4369 23.121 54.8462 23.4977 56.3402 23.9359L56.35 12.1622C54.517 11.4778 52.7036 11.3901 50.6555 11.7145C45.6084 12.5139 40.8241 15.4461 37.5614 19.9369C33.9061 24.7897 33.777 28.7092 33.795 34.0301L33.7434 38.912L29.062 39.6535L29.0443 50.9035L33.7257 50.1621L33.753 74.1141C33.7577 78.2033 37.4132 81.3218 41.452 80.6821C44.6893 80.1693 47.0718 77.3766 47.0681 74.0989L47.0384 48.0535Z"
        fill="#3E51FF"
      />
      <path
        d="M56.0364 56.0931L46.7468 57.5644L46.7291 68.8145L56.0187 67.3431L56.0294 73.5649C55.9699 77.9233 54.8313 80.2031 51.3934 80.7476C49.6379 81.0256 48.2286 80.649 46.7345 80.2108L46.7248 91.9844C48.5577 92.6689 50.3712 92.7566 52.4193 92.4322C57.4664 91.6328 62.2507 88.7006 65.5134 84.2098C69.1687 79.3569 69.2978 75.4374 69.2798 70.1166L69.3314 65.2346L74.0128 64.4932L74.0305 53.2431L69.3491 53.9846L69.3219 30.2955C69.3172 26.2064 65.6616 23.0879 61.6229 23.7276C58.3855 24.2403 56.003 27.0331 56.0068 30.3108L56.0364 56.0931Z"
        fill="#F5FBFF"
      />
      <path
        d="M75.2843 32.0317L26.7143 40.1484C24.0693 40.5904 22.1162 42.8566 22.0694 45.5379C22.0088 49.0083 25.1064 51.6861 28.5316 51.1242L77.1257 43.1535C79.8135 42.7126 81.8016 40.4129 81.8491 37.6896C81.9106 34.1661 78.7601 31.4509 75.2843 32.0317Z"
        fill="#3E51FF"
      />
      <path
        d="M75.2843 53.0665L26.7143 61.1832C24.0693 61.6252 22.1162 63.8914 22.0694 66.5727C22.0088 70.0431 25.1064 72.7209 28.5316 72.159L77.1257 64.1883C79.8135 63.7474 81.8016 61.4477 81.8491 58.7244C81.9106 55.2009 78.7601 52.4857 75.2843 53.0665Z"
        fill="#F5FBFF"
      />
    </svg>
  );
};

export default FunFactLogo;
